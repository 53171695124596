import {STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from 'constants'

import { useEffect, useState } from "react";
// Chakra imports
import { Box, Flex, Text, Spacer, useColorModeValue, AbsoluteCenter, Spinner } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import React from "react";

import {Select} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { chartTimeSeriesDataReducer } from "lib/reducer";
import LineChart from "components/Charts/LineChart";
import moment from "moment/moment";
import { handleGetGatewaySensorData } from "../../../../../redux/gateway";

const avg = arr => arr.reduce((acc,v,i,a)=>(acc+v/a.length),0);

const EnergyConsumption = ({ title, energyType, sensorMac }) => {
  const textColor = useColorModeValue("gray.700", "white");
  // {`${consumptionInLastPeriod}%`}

  const dispatch = useDispatch()

  const loggedIn =  useSelector(state => state.app.loggedIn)
  const orgId = useSelector(state => state.app.userOrgID)
  const gatewayStatus =  useSelector(state => state.gateway.status)
  const sensorData = useSelector(state => state.gateway.sensor_data)
  const sensors = useSelector(state => state.gateway.sensors)

  const [energyData, setEnergyData] = useState({ data: [], name: "" } )
  const [energyMinMaxDates, setEnergyMinMaxDates] = useState({min:0, max: 0})
  const [consumptionInLastPeriod, setconsumptionInLastPeriod] = useState(100)

  const [groupDataPeriod, setGroupDataPeriod] = useState(60) // 15 minutes
  const [dataTimeRange, setDataTimeRange] = useState({ start: 0, end: 0 })

  /*
  useEffect(() => {
    if (loggedIn) {

      setEnergyData({ data: [], name: "" })
      //setTemperatureData({ data: [], name: "" })
      //dispatch(handleGetGateways(orgId, ''))
    }
  }, [loggedIn])
  */

  useEffect(() => {
    console.log("FIRST TIME IN RECORD LOGS")
    if (gatewayStatus == STATUS_LOADING)  setEnergyData({ data: [], name: "" })
  },[gatewayStatus])


  useEffect(() => {
    let buffer_energy = []

    if(sensorData != undefined) {
      if(sensorData[0] != undefined && typeof(sensorData) !== "string") {

        let min = sensorData[0].data[0]["read_on_ts"]
        let max = sensorData[0].data.slice(-1)[0]["read_on_ts"]

        setEnergyMinMaxDates({ min: min, max: max })

        let temperature_analysis = [];
        for (var i in sensorData[0].data) {
          let econsumed = sensorData[0].data[i]["energy_consumed"];
          if(orgId == 5) econsumed = Math.round(econsumed / 1000.0)
          buffer_energy.push([sensorData[0].data[i]["read_on_ts"], econsumed])
        }

        // Group data by periods, if necessary
        if(groupDataPeriod !== false) {
          buffer_energy = chartTimeSeriesDataReducer(buffer_energy, groupDataPeriod)
        }

        let energyChartBuffer = { name: sensorData[0]["mac"], data: buffer_energy }
        setEnergyData(energyChartBuffer)
        //setEnergyExtendedData(  chartTimeSeriesDataReducer(buffer_energy, 24*60)) // Daily information
      }
    }
    //onsole.log("GROUP PERIOD: ", groupDataPeriod)
  }, [sensorData, groupDataPeriod])

  const changeTimeRange = (e) => {
    if(e.target.value > 0) {
      setDataTimeRange(e.target.value)
      let date_end = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      let date_start = moment().utc().subtract(e.target.value, 'd').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      dispatch(handleGetGatewaySensorData(sensorMac, date_start, date_end))
    }
  }

  return (
    <Card p='28px 10px 16px 0px' mb={{ sm: "26px", lg: "0px" }}>
      <CardHeader mb='20px' pl='22px'>
        <Box>
        <Flex direction='column' alignSelf='flex-start'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
            {title}
          </Text>
          <Text fontSize='md' fontWeight='medium' color='gray.400'>
            Unit:
            <Text
              as='span'
              color={consumptionInLastPeriod > 10000 ? "red.400" : "green.400"}
              fontWeight='bold'>
              {energyType}
            </Text>{" "}

          </Text>
        </Flex>
        </Box>
        <Spacer/>
        <Box pl='20px'>
          <Text>Time Range:</Text>
          <Box>

            <Select tag={"div"}  defaultValue={60} size="sm" variant='filled'  onChange={changeTimeRange} >
              <option value="7">Last Week</option>
              <option value="30">Last Month</option>
              <option value="90">Last 3 Month</option>
              <option value="180">Last 6 Month</option>
            </Select>
          </Box>
        </Box>
        <Spacer />
        <Box pl='20px'>
          <Text>Display Period:</Text>
          <Box>
            <Select tag={"div"}  defaultValue={60} size="sm" variant='filled'  onChange={(e) => setGroupDataPeriod(e.target.value)} >
              <option value="15">15 Minute</option>
              <option value="30">30 Minute</option>
              <option value="60">1 Hour</option>
              <option value="1440">24 Hour</option>
            </Select>
          </Box>
        </Box>
      </CardHeader>
      <Box w='100%' h={{ sm: "300px" }} ps='8px'>
        {gatewayStatus == STATUS_LOADING ?
          <AbsoluteCenter>
            <Spinner  mt={"0px"}  mb={"10px"}
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl'
            /></AbsoluteCenter> : null }
        <LineChart data={energyData} startDate={energyMinMaxDates.min} endDate={energyMinMaxDates.max}/>
      </Box>
    </Card>
  );
};

export default EnergyConsumption;
