// Chakra imports
import { AbsoluteCenter, Flex, SimpleGrid, Spinner, Text, Box, Spacer, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Custom icons
import {
  CartIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React, { useState, useEffect } from "react";
import ChartStatistics from "./ChartStatistics";
import { useSelector } from "react-redux";
import BarChart from "../../../../../components/Charts/BarChart";
import { STATUS_LOADING } from "constants";
import moment from "moment";

//{percentage > 0 ? `+${percentage}%` : `-${percentage}%`}
const TemperatureLevel = ({ title }) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");


  const orgId = useSelector(state => state.app.userOrgID)
  const gatewayStatus =  useSelector(state => state.gateway.status)
  const sensorData = useSelector(state => state.gateway.sensor_data)

  const [temperatureData, setTemperatureData] = useState({ data: [], name: "" } )
  const [temperatureDataInfo, setTemperatureDataInfo] = useState({minVal:0, maxVal:0, avgVal:0, variantVal:0})

  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [percentage, setPercentage] = useState(5)


  const avg = arr => arr.reduce((acc,v,i,a)=>(acc+v/a.length),0);
  const findVariance = (arr = []) => {
      if(!arr.length){
        return 0;
      };
      const sum = arr.reduce((acc, val) => acc + val);
      const { length: num } = arr;
      const median = sum / num;
      let variance = 0;
      arr.forEach(num => {
        variance += ((num - median) * (num - median));
      });
      variance /= num;
      return variance;
  };


  useEffect(() => {
    if (gatewayStatus == STATUS_LOADING) {
      setTemperatureData({ data: [], name: "" })

      // Calculate temperature Info
      temperatureDataInfo.minVal = 0
      temperatureDataInfo.maxVal = 0
      temperatureDataInfo.avgVal = 0
      temperatureDataInfo.variantVal = 0
      setTemperatureDataInfo(temperatureDataInfo)
    }
  },[gatewayStatus])

  useEffect(() => {
    let buffer_energy = []
    let buffer_temperature = []
    let temperature_active = ((orgId == 4 || orgId == 7 ) ? true : false)


    if(sensorData != undefined) {
      if(sensorData[0] != undefined && typeof(sensorData) !== "string") {

        let min = sensorData[0].data[0]["read_on_ts"]
        let max = sensorData[0].data.slice(-1)[0]["read_on_ts"]

        setMinDate(min)
        setMaxDate(max)

        let temperature_analysis = [];

        for (var i in sensorData[0].data) {
          if (sensorData[0].data[i]["temperature"] > -40) {
            if(!Number.isNaN(parseFloat(sensorData[0].data[i]["temperature"]))) {
              buffer_temperature.push([sensorData[0].data[i]["read_on_ts"], parseFloat(sensorData[0].data[i]["temperature"])])
              temperature_analysis.push(parseFloat(sensorData[0].data[i]["temperature"]))
            }
          }
        }

        let temperatureChartBuffer = { name: sensorData[0]["mac"], data: buffer_temperature }
        temperature_analysis = temperature_analysis.filter(function (value) {
          return !Number.isNaN(value);
        });

        // Calculate temperature Info
        temperatureDataInfo.minVal = Math.min(...temperature_analysis)
        temperatureDataInfo.maxVal = Math.max(...temperature_analysis)
        temperatureDataInfo.avgVal = avg(temperature_analysis).toFixed(1)
        temperatureDataInfo.variantVal = findVariance(temperature_analysis)
        temperatureDataInfo.lastVal = temperature_analysis[0]
        temperatureDataInfo.lastDate = moment(sensorData[0].data[0]["read_on_ts"]).format('D/MM/YYYY HH:mm ');
        setTemperatureDataInfo(temperatureDataInfo)

        console.log("Sıcaklık", temperatureChartBuffer.data.length)
        temperatureChartBuffer.data = temperatureChartBuffer.data.filter(function (value) {
          return !Number.isNaN(value);
        });
        console.log("SIcak ", temperatureChartBuffer.data.length)

        setTemperatureData(temperatureChartBuffer)

      }
    }

  }, [sensorData])

  return (
    <Card p='16px'>
      <CardBody>
        <Flex direction='column' w='100%'>
          <BarChart data={temperatureData} startDate={minDate} endDate={maxDate} />
          <Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
              <Box>
                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                {title}
                </Text>
              </Box>
              <Spacer />
              <Box>
                {gatewayStatus == STATUS_LOADING ?
                  <AbsoluteCenter>
                    <Spinner  mt={"0px"}  mb={"10px"}
                              thickness='4px'
                              speed='0.65s'
                              emptyColor='gray.200'
                              color='blue.500'
                              size='xl'
                    /></AbsoluteCenter> : null }
              </Box>
            <Text fontSize='md' fontWeight='medium' color='gray.400'>
              Unit:
              <Text
                as='span'
                color={percentage > 0 ? "red.400" : "red.400"}
                fontWeight='bold'> °C
              </Text>
            </Text>
          </Flex>
          <SimpleGrid gap={{ sm: "22px" }} columns={4}>
            <ChartStatistics
              title={"Maximum"}
              amount={temperatureDataInfo.maxVal}
              percentage={(temperatureDataInfo.maxVal/100*200)}
              icon={<WalletIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
              unit={"°C"}

            />
            <ChartStatistics
              title={"Minimum"}
              amount={temperatureDataInfo.minVal}
              percentage={(-1*temperatureDataInfo.minVal/100*200)}
              icon={<RocketIcon h={"15px"} w={"15px"} color={iconBoxInside} />}
              unit={"°C"}

            />
            <ChartStatistics
              title={"Average"}
              amount={temperatureDataInfo.avgVal}
              percentage={(-1*temperatureDataInfo.avgVal/100*200)}
              icon={<CartIcon h={"15px"} w={"15px"} color={iconBoxInside} /> }
              unit={"°C"}
            />
            <ChartStatistics
              title={"Latest Measurement"}
              amount={temperatureDataInfo.lastVal}
              percentage={100}
              icon={<CartIcon h={"15px"} w={"15px"} color={iconBoxInside} /> }
              unit={"°C" + " - @ " + temperatureDataInfo.lastDate}
            />
          </SimpleGrid>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default TemperatureLevel;
