import {createAction, handleActions} from 'redux-actions'

import {STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from 'constants'
import {
	getSensorTypes,
	getGateways, apiSetToken, getSensors, getSensorData, getEmeterConsumptionHistory
} from "lib/api";

const initialState = {
	status: STATUS_NONE,
	add_status: STATUS_NONE,
	emeter_status: STATUS_NONE,
	error: '',
	emeter_error: '',
	gateways: [],
	assignErr: '',
	sensor_types: [],
	sensors: [],
	gateway_sensor_logs: [],
	sensor_data: [],
	emeter_history_data: []
}

export const resetGateways = createAction('RESET_GATEWAY')
export const setGWErr = createAction('SET_GW_ERR')
export const setGateways = createAction('SET_GATEWAYS')
export const setSensors = createAction('SET_SENSORS')
export const setEmeterHistoryData = createAction('SET_EMETER_HISTORY_DATA')
export const setEmeterHistoryErr = createAction('SET_EMETER_HISTORY_ERR')
export const setEmeterHistoryStatus = createAction('SET_EMETER_HISTORY_STATUS')
export const setGWStatus = createAction('SET_GW_STATUS')
export const setGatewaySignalLogs= createAction('SET_GW_SIGNAL_LOGS')
export const setAssignErr = createAction('SET_ASSIGN_ERR')
export const setGatewayAddStatus = createAction('SET_GW_ADD_STATUS')
export const setSensorTypes = createAction('SET_SENSOR_TYPES')
export const setSensorData = createAction('SET_SENSOR_DATA')

export default handleActions({
	RESET_GATEWAY: () => (initialState),
	SET_GW_ERR: (state, action) => ({...state, error: action.payload}),
	SET_EMETER_HISTORY_ERR: (state, action) => ({...state, emeter_history_error: action.payload}),
	SET_EMETER_HISTORY_STATUS: (state, action) => ({...state, emeter_history_status: action.payload}),
	SET_EMETER_HISTORY_DATA: (state, action) => ({...state, emeter_history_data: action.payload}),
	SET_GATEWAYS: (state, action) => ({...state, gateways: action.payload}),
	SET_SENSORS: (state, action) => ({...state, sensors: action.payload}),
	SET_GW_STATUS: (state, action) => ({...state, status: action.payload}),
	SET_ASSIGN_ERR: (state, action) => ({...state, assignErr: action.payload}),
	SET_GW_ADD_STATUS: (state, action) => ({...state, add_status: action.payload}),
	SET_SENSOR_TYPES: (state, action) => ({...state, sensor_types: action.payload}),
	SET_GW_SIGNAL_LOGS: (state, action) => ({...state, gateway_sensor_logs: action.payload}),
	SET_SENSOR_DATA: (state, action) => ({...state, sensor_data: action.payload})
}, initialState)

export const handleGetOrgSensors = () => {
	return function (dispatch, getState) {
		const { userOrgID } = getState().app
		dispatch(setGWErr(''))
		dispatch(setGWStatus(STATUS_LOADING))
		getOrgSensors(userOrgID)
			.then(resp => {
				if (resp.ok) {
					dispatch(setSensors(resp.data))
					dispatch(setGWStatus(STATUS_SUCCESS))
				}
				else {
					dispatch(setGWErr(resp.data.message))
					dispatch(setGWStatus(STATUS_ERROR))
				}
			})
	}
}

export const handleGetGateways = (organization_id, location_id, search) => {
	return function (dispatch, getState) {
		dispatch(setGWErr(''))
		dispatch(setGWStatus(STATUS_LOADING))
		const {token} = getState().app
		apiSetToken(token)
		getGateways(organization_id, location_id, search)
			.then(resp => {
				if (resp.ok) {
					dispatch(setGateways(resp.data))
					dispatch(setGWStatus(STATUS_SUCCESS))
				}
				else {
					dispatch(setGWErr(resp.data.message))
					dispatch(setGWStatus(STATUS_ERROR))
				}
			})
	}
}

export const handleGetGatewaySensors = (gateway_id) => {
	return function (dispatch, getState) {
		const { userOrgID } = getState().app
		dispatch(setGWErr(''))
		dispatch(setGWStatus(STATUS_LOADING))
		getSensors(gateway_id, userOrgID)
			.then(resp => {
				if (resp.ok) {
					dispatch(setSensors(resp.data))
					dispatch(setGWStatus(STATUS_SUCCESS))
				}
				else {
					dispatch(setGWErr(resp.data.message))
					dispatch(setGWStatus(STATUS_ERROR))
				}
			})
	}
}

export const handleGetGatewaySensorData = (sensor_mac, date_start, date_end) => {
	return function (dispatch, getState) {
		const { userOrgID } = getState().app
		dispatch(setGWErr(''))
		dispatch(setGWStatus(STATUS_LOADING))
		getSensorData(userOrgID, sensor_mac, date_start, date_end)
			.then(resp => {
				if (resp.ok) {
					dispatch(setSensorData(resp.data))
					dispatch(setGWStatus(STATUS_SUCCESS))
				}
				else {
					dispatch(setGWErr(resp.data.message))
					dispatch(setGWStatus(STATUS_ERROR))
				}
			})
	}
}

export const handleGetEmeterConsumptionHistory = (sensor_mac, date_start, date_end) => {
	return function (dispatch, getState) {

		const { userOrgID } = getState().app

		dispatch(setEmeterHistoryErr(''))
		dispatch(setEmeterHistoryStatus(STATUS_LOADING))

		getEmeterConsumptionHistory(userOrgID, sensor_mac, date_start, date_end)
			.then(resp => {
				if (resp.ok) {
					dispatch(setEmeterHistoryData(resp.data))
					dispatch(setEmeterHistoryStatus(STATUS_SUCCESS))
				}
				else {
					dispatch(setEmeterHistoryErr(resp.data.message))
					dispatch(setEmeterHistoryStatus(STATUS_ERROR))
				}
			})
	}
}




export const handleCreateGateway = (gateway) => {
	return function (dispatch, getState) {
		dispatch(setGWErr(initialState.error))
		dispatch(setGatewayAddStatus(STATUS_LOADING))
		createGateway(gateway)
			.then(resp => {
				if (resp.ok) {
					let gateways = getState().gateway.gateways
					let offset = gateways.gateways.length >= 10 ? 1 : 0
					gateways.gateways = [
						resp.data,
						...gateways.gateways.slice(0, gateways.gateways.length - offset)
					]
					dispatch(setGateways(gateways))
					dispatch(setGatewayAddStatus(STATUS_SUCCESS))
				}
				else {
					dispatch(setGWErr(resp.data))
					dispatch(setGatewayAddStatus(STATUS_ERROR))
				}
			})
	}
}

export const assignOrg = (gateid, orgid) => {
	return function (dispatch) {
		dispatch(setAssignErr(''))
		setGateAssign(gateid, orgid)
			.then(resp => {
				if (resp.ok) {
					dispatch(setGateways(resp.data))
				}
				else {
					if (resp.data) {
						dispatch(setAssignErr(resp.data))
					}
					else if (resp.originalError.message) {
						dispatch(setAssignErr(resp.originalError.message))
					}
				}
			})
	}
}

export const assignSens = (sensid, patid, orgid) => {
	return function (dispatch) {
		dispatch(setAssignErr(''))
		setSensorAssign(sensid, patid, orgid)
			.then(resp => {
				if (resp.ok) {
					dispatch(setSensors(resp.data))
				}
				else {
					if (resp.data) {
						dispatch(setAssignErr(resp.data))
					}
					else if (resp.originalError.message) {
						dispatch(setAssignErr(resp.originalError.message))
					}
				}
			})
	}
}

export const handleGetSensorTypes = () => {
	return function (dispatch, getState) {
		const {userOrgID} = getState().app
		getSensorTypes(userOrgID)
			.then(resp => {
				if (resp.ok) {
					dispatch(setSensorTypes(resp.data))
				}
			})
	}
}


