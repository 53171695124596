import { useReducer, useState } from "react";
// Chakra imports
import {
  Flex,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue, AbsoluteCenter, Spinner
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import React, { useEffect } from "react";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import moment from 'moment'
import { total } from "react-big-calendar/lib/utils/dates";
import { handleGetEmeterConsumptionHistory } from "../../../../../redux/gateway";
import { useSelector } from "react-redux";
import { STATUS_LOADING } from "constants";
const AlertHistory = ({ title }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const [locationTimezone, setLocationTimezone] = useState('+0300')
/*
  const recordErr = useSelector(state => state.gateway.emeter_history_error)
  const recordStatus = useSelector(state => state.gateway.emeter_history_status)
  const recordLogs = useSelector(state => state.gateway.emeter_history_data)
*/
  const [recordStatus, setRecordStatus] = useState(false)
  const [recordLogsData, setRecordLogsData]  = useState([])
  const captions = ['Event Time', 'Alert Rule', 'Humidity Value']

  const [data, setData] = useState([])
  const [totalConsumption, setTotalConsumption] = useState(0)
  const [divider, setDivider] = useState(1000)

  const [bufferData, setBufferData] = useState([])

  const [energyExtendedData, setEnergyExtendedData] = useState([] )

  useEffect(() => {
    console.log("FIRST TIME IN RECORD LOGS")
    if (recordStatus == STATUS_LOADING)  setRecordLogsData([])
  },[recordStatus])
  /*
  useEffect(() => {
    if(recordLogs !== undefined) {
      if (recordLogs.length > 0) {
        let _total = {};

        recordLogs.map(record => {
          let _key = record._id.year + "_" + record._id.month
          if (_total[_key] === undefined) _total[_key] = 0
          _total[_key] += record.total
        })

      }
    }

  }, [recordLogs])
  */
  return (
    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='12px 0px 28px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <Flex align='center'>
            <Icon
              as={IoCheckmarkDoneCircleSharp}
              color='blue.400'
              w={4}
              h={4}
              pe='3px'
            />
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
              <Text fontWeight='bold' as='span'>
                Alert History
              </Text>{" "}
              for humidity over 55% events.
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      {recordStatus == STATUS_LOADING ?
        <AbsoluteCenter>
          <Spinner  mt={"0px"}  mb={"10px"}
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
          /></AbsoluteCenter> : null }
      <Table variant='simple' color={textColor}>
        <Thead>
          <Tr my='.8rem' ps='0px'>
            {captions.map((caption, idx) => {
              return (
                <Th color='gray.400' key={idx+"_subh_"+caption}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {
            recordLogsData.map((record,idx) => {
                return (
                  <Tr  my='.8rem' ps='0px' key={idx+"_thead_x"}>
                    <Td color='gray.800' key={idx+"_sub1"} > {moment.parseZone(record.date).utcOffset(locationTimezone).format("MMM DD, YYYY")}</Td>
                    <Td color='gray.800' key={idx+"_sub2"} >{(record.total / divider).toFixed(1)} {energyMetric}</Td>
                    <Td color='gray.800' key={idx+"_sub3"} ></Td>
                    <Td color='gray.800' key={idx+"_sub4"} >{( (record.total / totalConsumption[record._id.year + "_" + record._id.month]) * 100 ).toFixed(1) }%</Td>
                  </Tr>
                );
              }
            )}

        </Tbody>
      </Table>
    </Card>
  );
};

export default AlertHistory;
